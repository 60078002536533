@font-face {
    font-family: CalibreWeb;
    src:
        url('https://lumiere.nl/media/fonts/CalibreWeb-Semibold.woff2')
        format('woff2'),
        url('https://lumiere.nl/media/fonts/CalibreWeb-Semibold.woff')
        format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: CalibreWeb;
    src:
        url('https://lumiere.nl/media/fonts/CalibreWeb-Regular.woff2')
        format('woff2'),
        url('https://lumiere.nl/media/fonts/CalibreWeb-Regular.woff')
        format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Tiempo;
    src:
        url('https://lumiere.nl/media/fonts/Tiempo-.woff2') format('woff2'),
        url('https://lumiere.nl/media/fonts/Tiempo-.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Tiempo;
    src:
        url('https://lumiere.nl/media/fonts/Tiempo-Italic.woff2')
        format('woff2'),
        url('https://lumiere.nl/media/fonts/Tiempo-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

html,
body {
    background-color: transparent;
    color: #3b3530;
    font-family: CalibreWeb;
    font-style: normal;
    font-weight: normal;
    font-size: 100%;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
}

body.with-top-navbar {
    padding-top: 0px;
}

.navbar {
    display: none;
}

#addtocart,
button#confirm {
    border-radius: 0px;
    background-color: #ff6f5c;
    border-color: white;
    font-weight: 700;
    font-size: 1.375em;
}

#agenda {
    display: none;
}

#continueshopping,
#back-hint {
    display: none;
}

/* Do not enable this code, it also hides postal code field on Lumiere Card page */

/* .container.p-t-md .row > .col-md-3:last-child {
	display: none;
} */

.membership-enroll {
    position: relative;
}

/* stylelint-disable-next-line selector-id-pattern */
.membership-enroll input#v,
.membership-enroll input#directdebit_n {
    margin-left: 20px;
}

.membership-enroll input[type='radio'] {
    margin: 3px 5px 0;
}

.membership-enroll #enrolltype {
    border: 1px solid #d4d0c7;
}

.membership-enroll input[type='text'],
.membership-enroll input.datepicker {
    border: 1px solid #d4d0c7;
    padding: 2px 5px;
}

.membership-enroll #continue {
    background-color: #70c4d0;
    font-weight: bold;
    color: #fff;
    padding: 15px 20px;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 1em;
    text-decoration: none;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    border: none;
}

.membership-enroll #continue:hover {
    background-color: #e6c576;
}

@media only screen and (min-width: 1024px) {
    .membership-enroll #continue {
        padding: 15px 30px;
        margin-bottom: 40px;
        font-size: 1.375em;
    }
}

#membership-prolong-membership-card {
    max-width: 260px;
}

// form[action='purchase.php'] .p-b:nth-of-type(2) {
//     display: none;
// }

.seat-available,
.legend-available,
#legend-pane .legenditem-indicator.legend-available {
    background-image: url('/media/images/tickets/seat-available.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

div.legend-blocked,
.seat-blocked {
    background-image: url('/media/images/tickets/seat-blocked.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.seat-unavailable,
.legend-unavailable,
#legend-pane .legenditem-indicator.legend-unavailable {
    background-image: url('/media/images/tickets/seat-unavailable.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

div.peg,
div.legend-selected {
    background-image: url('/media/images/tickets/seat-selected.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.seat-available-seat-flag-wheelchair {
    background-image:
        url('/media/images/tickets/wheelchair.png'),
        url('/media/images/tickets/seat-available.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.seat-blocked-seat-flag-wheelchair {
    background-image:
        url('/media/images/tickets/wheelchair.png'),
        url('/media/images/tickets/seat-blocked.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.seat-available-seat-flag-wheelchair div.peg {
    background-image:
        url('/media/images/tickets/wheelchair.png'),
        url('/media/images/tickets/seat-selected.png') !important;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.rowindicator {
    font-size: 16px;
    color: #3b3530;
}

div#frontindicator {
    font-size: 16px;
    color: #3b3530;
    border: 2px solid #3b3530;
    border-top: none;
    padding: 10px 0;
    margin-left: 96px;

    // zaal 2 en 3
    .location-15 &,
    .location-12 & {
        width: 427px;
    }

    // zaal 4, 5 en 6
    .location-9 &,
    .location-10 &,
    .location-14 & {
        width: 282px;
    }

    // zaal 1
    .location-13 & {
        width: 569px;
    }
}

div.legenditem-description {
    font-size: 12px;
    color: #3b3530;
    margin-top: 10px;
}

div.legenditem-indicator {
    margin-right: 8px;
}
